import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Ripple, Scroll } from 'vuetify/lib/directives'
// import 'vuetify/src/stylus/app.styl'
// import 'vuetify/src/styles/main.sass'
import '@mdi/font/css/materialdesignicons.css'

const ufTheme = {
  primary: '#FA4616',
  secondary: '#ffffff',
  accent: '#18a7f4',
  error: '#D7182A',
  warning: '#FCAF17',
  info: '#593674',
  success: '#A8DCD9'
}

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
})

export default new Vuetify({
  theme: {
    themes: {
      light: ufTheme,
      dark: ufTheme
    },
    dark: false
  },
  customProperties: true,
  icons: {
    iconfont: 'mdi'
  },
  directives: {
    Scroll
  }
})
