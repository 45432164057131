import axios from 'axios'
import moment from 'moment'
import { EventBus } from './event_bus'

const fetchURL = 'https://cmsdb.darkcosmos.org/'
const appVersionAccessToken = '65c6b2af68d5bfd738d00116f88c940acbc6b5de'

// This stores all the callback functions that want to know if there is an error with the user
const userErrorSubs = []

// var axiosInstance = axios.create({
//   baseURL: fetchURL,
//   headers: {
//     'X-Auth-Token': 'apikey=' + devApiKey
//   }
// })

export default {
  // This allows for setting the API key dynamically
  setDevApiKey (newKey) {
    localStorage.setItem('devAPIKey', newKey)
  },
  getDevApiKey () {
    return localStorage.getItem('devAPIKey') || ''
  },
  // This gets the list of members
  getUserData (asyncCB) {
    axiosGet('fetch/members', asyncCB)
  },
  // TODO: Make this into a user->avatar map getter
  getOneUserData (asyncCB, userId) {
    this.getUserData(userDataResp => {
      userDataResp.members.map(member => {
        if (member.user_id === userId) {
          asyncCB({
            name: member.display_name,
            text: 'Submit as ' + member.display_name,
            avatar: this.$root.$data.formAvatarURL(member.profile_picture),
            id: member.user_id,
            callback: () => console.log('Clicked on ' + member.display_name)
          })
        }
      })
    })
  },
  // This requests for the details of a particular LED
  getLEDDetail (postData, asyncCB) {
    axiosPost('fetch/led', postData, asyncCB)
  },
  getLEDExpDetail (postData, asyncCB) {
    axiosPost('fetch/ledexperiment', postData, asyncCB)
  },
  getResultAOs (postData, asyncCB) {
    axiosPost('fetch/resultaos', postData, asyncCB)
  },
  getRawAOs (postData, asyncCB) {
    axiosPost('fetch/rawaos', postData, asyncCB)
  },
  getSpectra (postData, asyncCB) {
    axiosPost('fetch/ledexperiment_matchingspectrum', postData, asyncCB)
  },
  getRawIVP (postData, asyncCB) {
    axiosPost('fetch/ledexperiment_matchingrawivp', postData, asyncCB)
  },
  getDuty (postData, asyncCB) {
    axiosPost('fetch/ledexperiment_matchingduty', postData, asyncCB)
  },
  getCustom (postData, asyncCB) {
    axiosPost('fetch/ledexperiment_customelements', postData, asyncCB)
  },

  // This gets the form data for adding notes
  postLEDNoteForm (postData, asyncCB) {
    axiosPost('form/addnote', postData, asyncCB)
  },
  // This gets the data for running an experiment
  postLEDExpForm (postData, asyncCB) {
    axiosPost('form/newmeasurement', postData, asyncCB)
  },
  getExpDetail (postData, asyncCB) {
    axiosPost('fetch/experiment', postData, asyncCB)
  },
  // This is to delete experiments
  postExpDelete (postData, asyncCB) {
    axiosPost('action/hideexp', postData, asyncCB)
  },
  // TODO: This is to delete notes
  postNoteDelete (postData, asyncCB) {
    axiosPost('', postData, asyncCB)
  },
  // AO stuff
  getAOList (postData, asyncCB) {
    axiosPost('fetch/aos', postData, asyncCB)
  },
  getAOFilterForm (asyncCB) {
    axiosGet('form/filterao', asyncCB)
  },
  getResultFilterForm (asyncCB) {
    axiosGet('form/filterresult', asyncCB)
  },
  getAOMatlabCode (postData, asyncCB) {
    axiosPost('form/aosmatlab', postData, asyncCB)
  },
  getResultMatlabCode (postData, asyncCB) {
    axiosPost('form/resultmatlab', postData, asyncCB)
  },
  // This gets a file from an LED note, this does not have user info
  getNoteFile (filename, version, asyncCB) {
    // Version can be one of: chip, thumbnail, preview or original
    axios.post(formURL('files/viewnote'), formPostFromArray([
      { key: 'file', value: filename },
      { key: 'version', value: version },
      { key: 'apikey', value: localStorage.getItem('devAPIKey') }
    ]))
      .then((response) => {
        asyncCB(response)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  // This requests the form data for adding a new LED
  // .getNewLedForm = (asyncCB) => {
  //   axiosGet('form/newled', asyncCB)
  // }
  // This posts data for adding a new LED, and receives an updated form taking new info into account
  postNewLedForm (postData, asyncCB) {
    axiosPost('form/newled', postData, asyncCB)
  },
  // This registers callbacks for if there is an issue with the user, picked up by the user checking wrapper
  subToUserErrors (id, subCB) {
    let alreadyAdded = false

    const newCb = { id: id, callback: subCB }

    userErrorSubs.forEach(cb => {
      if (newCb.id === cb.id) {
        alreadyAdded = true
      }
    })

    if (!alreadyAdded) {
      userErrorSubs.push(newCb)
    }
  },
  // User Login/Logout Stuff
  // Note that these don't use the user checking wrapper, as that wouldn't make sense...
  getLoginForm (asyncCB) {
    axios.get(formURL('form/login'))
      .then((response) => {
        asyncCB(response)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  postLogin (loginData, asyncCB) {
    axios.post(formURL('form/login'), loginData)
      .then((response) => {
        asyncCB(response)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  logout (asyncCB) {
    axios.get(formURL('fetch/logout'))
      .then((response) => {
        asyncCB(response)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  // Get the latest version from the github releases
  getLatestVersion (asyncCB) {
    let gitAPIURL = 'https://api.github.com/repos/Osyrus/CMSDatabaseWebclient/releases?access_token='
    gitAPIURL += appVersionAccessToken

    axios.get(gitAPIURL)
      .then(response => {
        asyncCB(response)
      })
      .catch(error => {
        console.log('Error getting version from github', error)
      })
  },
  getSingleExpData (postData, asyncCB) {
    axios.post(formURL('form/newmeasurement'), formPostFromArray(postData))
      .then((response) => {
        asyncCB(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

// Wrappers and helper functions

// This wraps the axios get method with the commonly used pattern
function axiosGet (locURL, asyncCB) {
  axios.get(formURL(locURL))
    .then((response) => {
      processResponse(response, asyncCB)
    })
    .catch((error) => {
      console.log(error)
    })
}
// And this is for posting
function axiosPost (locURL, postData, asyncCB) {
  axios.post(formURL(locURL), formPostFromArray(postData))
    .then((response) => {
      processResponse(response, asyncCB)
    })
    .catch((error) => {
      console.log(error)
    })
}

// This checks the user data, and if all is good calls the callback function that triggered it.
function processResponse (response, callback) {
  // This is the data structure that is send to the callbacks
  const userErrorResponse = { loggedIn: true, hasAccess: true }
  // First, check if there is any user data!
  if (!(response.data.user instanceof Object) || response.data.user === null) {
    userErrorResponse.loggedIn = false
    userErrorResponse.hasAccess = false
  } else if (response.data.access === 'no') {
    userErrorResponse.hasAccess = false
  }
  // Check if the user has access
  if (userErrorResponse.loggedIn && userErrorResponse.hasAccess) {
    // The old event bus way
    EventBus.$emit('newUserData', response.data.user)
    // If so, process the data
    // If the returned data has fields in it, then parse them in a way that is suited for the client.
    if (response.data.fields != null) {
      response.data.fields = parseFieldsFromJSON(response.data.fields)
    }
    if (response.data.pages != null) {
      response.data.pages.forEach(page => {
        if (page.fields != null) {
          page.fields = parseFieldsFromJSON(page.fields)
        }
      })
    }
    // Now send the data back by way of a function callback
    callback(response.data)
  } else {
    // Used in dev
    console.log('A user error was found, see following response data.')
    console.log(response)
    // TODO: This should be moved to the EventBus system
    // If not, then trigger all the registered user error callbacks
    userErrorSubs.forEach(cb => {
      cb.callback(userErrorResponse)
    })
  }
}

// These are a couple of get/post parsers for the server
// This forms the response JSON into fields that I can use
function parseFieldsFromJSON (rawJSONFields) {
  const entryFields = []

  rawJSONFields.map(fieldData => {
    // Create the rules array, used to store the validation functions
    fieldData.vRules = []
    // First, add a general rule to ensure that the field is filled out, if required
    if (fieldData.required) {
      fieldData.vRules.push(v => !!v || fieldData.title + ' is required')
    }
    // Now add any patterns, if they exist
    // if (fieldData.pattern != null) {
    //   fieldData.vRules.push(v => /fieldData.pattern/.test(v) || fieldData.title + ' must be valid')
    // }
    // If a text field is null, it gets interpreted like that, which is silly. Get rid of that nonsense!
    if (fieldData.value === 'null') {
      fieldData.value = ''
    }
    // Check for errors, and set the error flag accordingly
    fieldData.inError = fieldData.error != null
    // Convert from unix time into a time and a date individually, if datetime
    if (fieldData.type === 'datetime' || fieldData.type === 'datetime_unix') {
      const timestamp = moment.unix(fieldData.value)
      fieldData.time = timestamp.format('HH[:]mm')
      fieldData.rawTime = timestamp.format('HHmmss')
      fieldData.date = timestamp.format('YYYY[-]MM[-]DD')
      fieldData.timeMenu = false
      fieldData.dateMenu = false
    }
    // Add a nosubmit entry, until Simon does it...
    fieldData.nosubmit = (fieldData.type === 'divider') || (fieldData.type === 'code')
    // Finally, add the field to the main array
    entryFields.push(fieldData)
  })

  return entryFields
}
// This forms the post data from an array
function formPostFromArray (formDataArray) {
  // console.log('Requested array to post', formDataArray)

  // Reduce all the fields key:value pairs to a single string
  return formDataArray.reduce((entries, entry) => {
    // Skip any entries that are not meant to be sent back, or that have no key to send anyway
    if (!entry.nosubmit && entry.key) {
      // Make sure that the value sent is an empty string, not the string "null"!!!
      if (!entry.value) {
        entry.value = ''
      }
      // Add a preceding & if not the first item to be added to the string
      if (entries.length > 0) entries += '&'
      // Add the string to the accumulator
      entries += (encodeURIComponent(entry.key) + '=' + encodeURIComponent(entry.value))
    }

    return entries
  }, '')
}

// This forms the URL from just the path required, to the full thing
function formURL (scriptName) {
  let fetchSuffix = '.php'
  // const fetchSuffix = '.php?noauth=true'

  // if (process.env.NODE_ENV === 'development' && localStorage.getItem('devAPIKey')) {
  if (localStorage.getItem('devAPIKey')) {
    fetchSuffix += '?apikey=' + localStorage.getItem('devAPIKey')
  }

  return fetchURL + scriptName + fetchSuffix
}
