import Vue from 'vue'
import Vuex from 'vuex'

// import { createPersistedState, createSharedMutations } from 'vuex-electron'
import serverData from './modules/serverData'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    serverData
  },
  plugins: [
    // createPersistedState(),
    // createSharedMutations()
  ],
  strict: debug
})
