// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import './plugins/markdown'
import './plugins/codeHighlighting'
import App from './App'
import router from './router'
import store from './store'
// import { BrowserWindow } from 'electron'

import si from './scripts/server_interface'

Vue.config.productionTip = false

const userData = {}
/*
const win = new BrowserWindow({
  webPreferences: {
    nodeIntegration: true
  }
})
win.show()
*/

// The globally required avatar processor
const avatarURL = 'https://members.darkcosmos.org/memberuploads/small/'
const avatarSuffix = '.jpg'
const formAvatarURL = (profilePictureName) => {
  return profilePictureName ? avatarURL + profilePictureName + avatarSuffix : null
}

// const inDev = process.env.NODE_ENV === 'development'
const inDev = true

/* eslint-disable no-new */
new Vue({
  el: '#app',
  vuetify,
  router,
  store,
  components: { App },
  data: { si, userData, formAvatarURL, inDev },
  template: '<App/>'
})
