import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    // { path: '/', redirect: '/cmsdb' },
    { path: '/', redirect: '/home' },
    {
      path: '/home',
      name: 'home',
      meta: {
        title: 'LED Tracker Home'
      },
      component: () => import('@/views/Home')
    },
    {
      path: '/leds',
      name: 'led_list',
      meta: {
        title: 'LED List'
      },
      component: () => import('@/views/LEDList'),
      children: [
        {
          path: 'detail/:led_id',
          name: 'led_detail',
          meta: {
            title: 'LED Detail'
          }
        }
      ]
    },
    {
      path: '/experiments',
      name: 'new_exp',
      meta: {
        title: 'Experiments'
      },
      component: () => import('@/views/ExpList'),
      children: [
        {
          path: 'run/:exp_id',
          name: 'run_exp',
          meta: {
            title: 'Run Experiment'
          }
        }
      ]
    },
    {
      path: '/aolist',
      name: 'ao_list',
      meta: {
        title: 'AO List'
      },
      component: () => import('@/views/AOList')
    },
    {
      path: '/report',
      name: 'report',
      meta: {
        title: 'Reports'
      },
      component: () => import('@/views/Report')
    }
    // { path: '*', component: NotFoundComponent }
  ]
})
