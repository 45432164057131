<template>
  <v-app>
    <!-- This is the main top toolbar -->
    <v-app-bar
      app
      dark
      dense
      color="primary"
    >
      <!-- This is the toggle menu button -->
      <v-app-bar-nav-icon @click.stop="showDrawer = !showDrawer" />

      <!-- This is the title displayed in the menu toolbar -->
      <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>

      <!-- This is where you can put more buttons on the right of the toolbar -->

      <v-spacer />
      <v-btn
        v-if="!loading"
        :disabled="loading"
        icon
        @click.stop="emitGlobalRefreshEvent"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
      <v-progress-circular
        v-else
        indeterminate
      />

      <!--<v-icon-->
      <!--@click.stop="emitGlobalRefreshEvent"-->
      <!--&gt;mdi-refresh</v-icon>-->
    </v-app-bar>

    <!-- This is the main nav panel at the bottom of the screen -->
    <v-bottom-navigation
      :input-value="showNav"
      app
      dense
      grow
    >
      <template v-for="navItem in items">
        <v-btn
          :key="navItem.linkTo.name"
          text
          :to="navItem.linkTo"
          :value="navItem.linkTo.name"
        >
          <span>{{ navItem.title }}</span>
          <v-icon large>
            {{ navItem.icon }}
          </v-icon>
        </v-btn>
      </template>
    </v-bottom-navigation>

    <!-- This is the left side navigation drawer -->
    <v-navigation-drawer
      v-model="showDrawer"
      app
      :temporary="!miniDrawer"
      enable-resize-watcher
      :mini-variant="miniDrawer"
    >
      <!-- User info and actions -->
      <v-list class="pt-1">
        <!-- This is the "expand" right arrow, to transition to the full drawer size -->
        <v-list-item
          v-if="miniDrawer"
          @click.stop="miniDrawer = !miniDrawer"
        >
          <v-list-item-action>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-action>
        </v-list-item>
        <!-- This is the users avatar and name, to show who is logged in -->
        <v-list-item
          v-if="loggedIn"
        >
          <v-list-item-avatar>
            <img
              v-if="!useIconAvatar"
              :src="userData.avatar"
              @error="switchToGenericAvatar"
            >
            <v-icon v-else>
              mdi-face
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ userData.display_name }}</v-list-item-title>
            <v-list-item-subtitle>{{ userData.email_address }}</v-list-item-subtitle>
          </v-list-item-content>
          <!-- This is the "shrink" left arrow, that shrinks the drawer and allows it to stay -->
          <v-list-item-action>
            <v-btn
              icon
              @click.stop="miniDrawer = !miniDrawer"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <!-- This is where the quick access buttons below the user info are defined -->
        <v-list-item>
          <!-- This is the settings button, it shows in mini as well, but just as an icon -->
          <v-list-item-action>
            <v-btn
              text
              @click.native="openLoginDialog"
            >
              <v-icon :left="!miniDrawer">
                {{ loggedIn ? 'mdi-swap-horizontal' : 'mdi-account-outline' }}
              </v-icon>
              <span v-if="!miniDrawer">
                {{ loggedIn ? 'Switch User' : 'Login' }}
              </span>
            </v-btn>
          </v-list-item-action>
          <!-- This is the logout button, only shows in the expanded menu, to prevent accidents -->
          <v-list-item-action
            v-if="!miniDrawer && loggedIn"
            class="pl-2"
          >
            <v-btn
              text
              @click.native="logoutUser"
            >
              <v-icon left>
                mdi-exit-to-app
              </v-icon>
              <span>Logout</span>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <template v-if="loggedIn && !miniDrawer">
        <!-- Some extra data for the user, if they are logged in -->
        <v-subheader>Information</v-subheader>

        <!-- Show the time since last active -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-timelapse</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            Last active {{ timeSince(userData.last_active) }}
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <!-- This is the indicator of the current client version -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-webpack</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Client version: v{{ version }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-subheader>Tools</v-subheader>

      <!-- These is the API key buttons -->
      <!-- If in development, show the API key setting -->
      <v-list-item
        @click="showAPIDialog = true"
      >
        <v-list-item-action>
          <v-icon>mdi-developer-board</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            Set API Key (Dev Mode)
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Otherwise, if in production and logged in, show the users API key -->
      <v-list-item
        v-if="loggedIn && !$root.$data.inDev"
        @click="writeAPIToClipboard"
      >
        <v-list-item-action>
          <v-icon>mdi-content-copy</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            Copy API Key to Clipboard
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Here is the bulk Matlab uploader script tool button -->
      <v-list-item
        v-if="loggedIn"
        @click="openBulkTool"
      >
        <v-list-item-action>
          <v-icon>mdi-code-brackets</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            MATLAB Bulk Scripts Tool
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- This is the websites changelog, only visible if logged in and expanded -->
      <template v-if="loggedIn && !miniDrawer">
        <v-subheader>Changelog</v-subheader>

        <v-expansion-panels>
          <v-expansion-panel
            v-for="gitV in gitVersions"
            :key="gitV.version"
          >
            <v-expansion-panel-header
              expand-icon="mdi-menu-down"
            >
              v{{ gitV.version }} - {{ gitV.datetime }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat>
                <v-card-text>
                  <vue-simple-markdown
                    :source="gitV.desc"
                  />
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-navigation-drawer>

    <!-- This is the main content bit, with the views filled by the router, and dialogs -->
    <v-main>
      <!-- This is the part of the view that is filled by the router to whatever view is in the URL -->
      <template v-if="loggedIn && haveAccess">
        <v-slide-y-transition mode="out-in">
          <router-view />
        </v-slide-y-transition>
      </template>

      <!-- These are rendered in the case that there is a problem with the user -->
      <UserErrorCard
        v-else
        :error-type="!loggedIn ? 'login' : (!haveAccess ? 'access' : 'unknown')"
        @onLoginRequest="openLoginDialog"
        @onLogoutRequest="logoutUser"
      />

      <!-- This is the info snackbar, used to tell the user small things -->
      <v-snackbar
        v-model="showInfo"
        top
        :timeout="6000"
        color="info"
      >
        {{ infoMessage }}
        <v-btn
          text
          color="primary"
          @click="showInfo = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <!-- This is the "new update" snackbar -->
      <v-snackbar
        v-model="showNewUpdate"
        bottom
        left
        :timeout="-1"
      >
        New web client version detected:
        <v-tooltip
          v-if="gitVersions[0]"
          top
        >
          <template #activator="{ on, attrs }">
            <v-subheader
              v-bind="attrs"
              v-on="on"
            >
              v{{ gitVersions[0].version }}
            </v-subheader>
            <span>Update Note: {{ gitVersions[0].desc }}</span>
          </template>
        </v-tooltip>

        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              text
              color="primary"
              v-on="on"
              @click="forcePageRefresh"
            >
              <v-icon class="pr-1">
                mdi-update
              </v-icon>
              Update
            </v-btn>
            <span>Note: This will refresh the page!</span>
          </template>
        </v-tooltip>
      </v-snackbar>

      <!-- This is the error dialog, which is a popup dialog as this needs to be a bit more obvious -->
      <v-dialog
        v-model="showError"
        max-width="500px"
      >
        <v-card
          tile
          class="popup-dialog"
        >
          <v-app-bar
            flat
            color="error"
          >
            <v-toolbar-title>Error: {{ errorTitle }}</v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <div>{{ errorMessage }}</div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              rounded
              @click="showError = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- This is the login form dialog -->
      <v-dialog
        v-model="loginForm"
        max-width="500px"
        persistent
      >
        <v-card
          tile
          class="popup-dialog"
        >
          <v-app-bar
            flat
            color="primary"
          >
            <v-toolbar-title>DarkCosmos Login</v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-form
                  ref="loginFormDialog"
                  v-model="loginFormValid"
                  lazy-validation
                >
                  <template v-for="loginField in loginFields">
                    <FormField
                      :ref="loginField.key"
                      :key="loginField.key"
                      :entry="loginField"
                      :pull-focus="loginField.key === 'username' && loginForm"
                      @onEnter="loginFieldEnter(loginField.key)"
                    />
                  </template>

                  <v-btn
                    color="primary"
                    rounded
                    @click.stop="postLogin"
                  >
                    <v-icon class="pr-1">
                      mdi-lock-open-outline
                    </v-icon>
                    Login
                  </v-btn>
                  <v-btn
                    text
                    rounded
                    @click.stop="loginForm = false"
                  >
                    <v-icon class="pr-1">
                      mdi-close
                    </v-icon>
                    Cancel
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- This is the add API key dialog -->
      <v-dialog
        v-model="showAPIDialog"
        max-width="500px"
      >
        <v-card
          tile
          class="popup-dialog"
        >
          <v-app-bar
            flat
            color="primary"
          >
            <v-toolbar-title>Development API Key</v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-form>
                  <v-text-field
                    v-model="newApiKey"
                    label="API Key"
                  />

                  <v-btn
                    color="primary"
                    rounded
                    @click.stop="saveAPIKey"
                  >
                    <v-icon class="pr-1">
                      mdi-content-save
                    </v-icon>
                    Save
                  </v-btn>
                  <v-btn
                    text
                    rounded
                    @click.stop="showAPIDialog = false"
                  >
                    <v-icon class="pr-1">
                      mdi-close
                    </v-icon>
                    Cancel
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- This is the warning to the user that they have not been active for awhile -->
      <v-dialog
        v-model="showTimeoutWarning"
        max-width="500px"
        persistent
      >
        <v-card
          tile
          class="popup-dialog"
        >
          <v-app-bar
            flat
            color="error"
          >
            <v-toolbar-title>Timeout Warning!</v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <div>You have been inactive for some time, do you want to continue or logout?</div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              rounded
              @click="updateUserData"
            >
              Continue
            </v-btn>
            <v-btn
              text
              rounded
              @click="logoutUser"
            >
              Logout
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- This is the bulk downloader tool dialog thingo -->
      <BulkDownloaderTool
        :dialog-open="bulkDialogOpen"
        @closeClicked="closeBulkTool"
      />
    </v-main>
  </v-app>
</template>

<script>
import { EventBus } from './scripts/event_bus'
import moment from 'moment'
import { version } from '../package'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    BulkDownloaderTool: () => import('./components/BulkDownloaderTool'),
    FormField: () => import('./components/FormField'),
    UserErrorCard: () => import('./components/UserErrorCard')
  },
  data () {
    return {
      showDrawer: false,
      miniDrawer: false,
      showNav: true,
      items: [
        { icon: 'mdi-home', title: 'Home', linkTo: { name: 'home' } },
        { icon: 'mdi-view-list', title: 'Diode List', linkTo: { name: 'led_list' } },
        { icon: 'mdi-lightbulb-outline', title: 'Experiments', linkTo: { name: 'new_exp' } },
        { icon: 'mdi-code-braces', title: 'AO List', linkTo: { name: 'ao_list' } },
        { icon: 'mdi-file-chart', title: 'Report', linkTo: { name: 'report' } }
      ],
      version: version,
      gitVersions: [],
      showNewUpdate: false,
      // userData: {},
      useIconAvatar: false,
      loginForm: false,
      loginFields: [],
      loginFormValid: true,
      // loggedIn: true,
      // haveAccess: true,
      infoMessage: '',
      showInfo: false,
      errorTitle: '',
      errorMessage: '',
      showError: false,
      showAPIDialog: false,
      newApiKey: '',
      showTimeoutWarning: false,
      bulkDialogOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'loggedIn',
      'haveAccess',
      'loading'
    ])
  },
  watch: {
    $route: function () {
      // This is called every time the route changes

      // Also do a check every time the route changes
      // TODO: Is this excessive? Maybe only when it hasn't been checked for awhile?
      this.getLatestVersion()
    }
  },
  mounted () {
    // Subscribe to user errors
    this.$root.$data.si.subToUserErrors('App', this.processUserError)
    // Update the currently logged in user on mount
    this.updateUserData()
    // Subscribe to info and error messages
    EventBus.$on('info', this.openSnackbar)
    EventBus.$on('error', this.openError)
    // Get the current API key
    this.newApiKey = this.$root.$data.si.getDevApiKey()
    // Create a timer to check if the user has not timed out
    setInterval(this.checkTimeout, 30000)
    // Update the user data when the event fires
    EventBus.$on('newUserData', newData => {
      this.processUserData(newData)
      // Also check this timeout every time there is new user information
      this.checkTimeout()
    })
    // Check for a new version upon first loading the website
    this.getLatestVersion()
  },
  methods: {
    ...mapActions({
      updateUserData: 'refreshUserData',
      updateExpList: 'refreshExpList'
    }),
    openBulkTool: function () {
      console.log('Opening bulk script downloader')
      this.bulkDialogOpen = true
      this.showDrawer = false
      this.updateExpList()
    },
    closeBulkTool: function () {
      console.log('Closing bulk script downloader')
      this.bulkDialogOpen = false
    },
    forcePageRefresh: function () {
      console.log('Clearing the cache and updating from the server')
      // Force a refresh of the cache
      window.location.reload(true)
    },
    getLatestVersion: function () {
      this.$root.$data.si.getLatestVersion(response => {
        // Clear the array
        this.gitVersions = []
        // Load in the new data
        response.data.forEach(versionData => {
          this.gitVersions.push({
            version: versionData.tag_name.substring(1),
            timestamp: moment(versionData.published_at).unix(),
            datetime: moment(versionData.published_at).calendar(),
            desc: versionData.body
          })
        })

        // Turn the version strings into numbers, taking into account major, minor and patch levels.
        const gitVersionNumber = this.reduceVersionNumber(this.gitVersions[0].version)
        const versionNumber = this.reduceVersionNumber(this.version)

        // If the client is at or above the git version, everything it fine.
        // It could be above because the release on Git may not be published yet, which is fine.
        if (versionNumber >= gitVersionNumber) {
          // EventBus.$emit('info', 'Web client up-to-date')
          // console.log('Web client up-to-date:', this.gitVersion)
        } else {
          console.log('Update detected. Current version:', this.version, ', new version:', this.gitVersion)
          this.showNewUpdate = true
        }
      })
    },
    reduceVersionNumber: function (ver) {
      return ver.split('.').reverse().reduce((sum, num, index) => {
        sum += (Number(num) * Math.pow(100, index))
        return sum
      }, 0)
    },
    loginFieldEnter: function (key) {
      if (key === 'username') {
        // Here we want to pull focus onto the password

        const passwordField = this.$refs.password[0].$children[0]
        this.$nextTick(passwordField.focus)
      } else if (key === 'password') {
        // Here we want to submit the login form
        this.postLogin()
      }
    },
    checkTimeout: function () {
      if (this.userData.last_active && this.userData.time_out && this.loggedIn) {
        const timeDiff = moment().subtract(this.userData.last_active, 'seconds').unix()

        if (timeDiff > this.userData.time_out) {
          // User has been timed out and logged out on the server
          console.log('Timed out, login again please')
          // Set the flag to show this, as there has been no server comms to update it
          this.loggedIn = false
          this.showTimeoutWarning = true
          // Prompt them to login again
          this.openLoginDialog()
        } else {
          this.showTimeoutWarning = timeDiff > (this.userData.time_out / 2)
        }
      }
    },
    timeSince: function (unixTime) {
      const baseTime = moment.unix(unixTime)
      const currentTime = moment.now()

      return baseTime.from(currentTime)
    },
    openLoginDialog: function () {
      this.$root.$data.si.getLoginForm(loginResp => {
        this.loginFields = loginResp.data.fields
        this.loginForm = true
      })
    },
    postLogin: function () {
      if (this.$refs.loginFormDialog.validate()) {
        let loginPostData = ''

        this.loginFields.forEach(field => {
          if (field.value == null) {
            field.value = ''
          }

          loginPostData += '&' + field.key + '=' + field.value
        })

        loginPostData += '&action=login'

        // Cut the first &
        loginPostData = loginPostData.substring(1, loginPostData.length)

        this.$root.$data.si.postLogin(loginPostData, loginResp => {
          this.loginFields = loginResp.data.fields

          if (loginResp.data.action === 'granted') {
            console.log('Login Successful')
            this.loginForm = false
            this.updateUserData()
          } else if (loginResp.data.action === 'error') {
            console.log(loginResp.data.error_message)
          }
        })
      }
    },
    logoutUser: function () {
      this.$root.$data.si.logout(logoutResp => {
        console.log(logoutResp.data)
        this.loggedIn = false
        this.updateUserData()
      })
    },
    // updateUserData: function () {
    //   // console.log('Requested to continue session')
    //
    //   this.$root.$data.si.getUserData(responseData => {
    //     // console.log('Continue session request received')
    //   })
    // },
    processUserData: function (userData) {
      // this.userData = userData
      //
      // if (this.userData.profile_picture !== '') {
      //   this.userData.avatar = this.$root.$data.formAvatarURL(this.userData.profile_picture)
      //   this.useIconAvatar = false
      // } else {
      //   this.useIconAvatar = true
      // }
      //
      // this.$root.$data.userData = this.userData
      // this.loggedIn = true
      // this.haveAccess = this.userData.access !== 'no'

      // Check the timeout value after updating all the users data
      this.checkTimeout()
      // console.log('Last active:', this.userData.last_active)
    },
    switchToGenericAvatar: function () {
      this.useIconAvatar = true
      console.log('Can\'t load avatar URL, falling back to icon')
    },
    processUserError: function (userErrorResponse) {
      if (!userErrorResponse.loggedIn) {
        console.log('You are not logged in')
        this.loggedIn = false
      } else if (!userErrorResponse.hasAccess) {
        console.log('You are logged in, but do not have access')
        this.haveAccess = false
      }
    },
    emitGlobalRefreshEvent: function () {
      EventBus.$emit('refresh')
    },
    openSnackbar: function (message) {
      this.infoMessage = message
      this.showInfo = true
    },
    openError: function (errorData) {
      this.errorTitle = errorData.title
      this.errorMessage = errorData.message
      this.showError = true
    },
    saveAPIKey: function () {
      this.$root.$data.si.setDevApiKey(this.newApiKey)
      this.showAPIDialog = false
      console.log('Saved new API Key', this.newApiKey)

      window.location.reload()
    },
    writeAPIToClipboard: function () {
      window.navigator.clipboard.writeText(this.userData.api_key).then(() => {
        EventBus.$emit('info', 'API key copied to clipboard successfully')
      }, () => {
        EventBus.$emit('info', 'Copy to clipboard failed... Outdated browser?')
      })
    }
  }
}
</script>

<style scoped>
</style>
