import axios from 'axios'
import moment from 'moment'
import utils from '../../scripts/utilities'
import { EventBus } from '../../scripts/event_bus'

const verbose = process.env.NODE_ENV !== 'production'

const fetchURL = 'https://cmsdb.darkcosmos.org/'
// const appVersionAccessToken = '65c6b2af68d5bfd738d00116f88c940acbc6b5de'

const state = {
  loggedIn: false,
  haveAccess: false,
  loading: false,
  userData: {},
  ledList: [],
  membersList: [],
  expList: [],
  aoList: [],
  resultList: [],
  preselectedResultList: [],
  aoListFilters: [],
  resultListFilters: [],
  welcomeNotes: [],
  welcomeIncomExps: [],
  welcomeNoAOs: []
}

const mutations = {
  setUserData (state, newUserData) {
    if (newUserData.user) {
      state.userData = newUserData.user

      if (state.userData.profile_picture !== '') {
        state.userData.avatar = utils.formAvatarURL(state.userData.profile_picture)
      }

      state.loggedIn = true
      state.haveAccess = state.userData.access !== 'no'

      EventBus.$emit('newUserData', newUserData.user)
    }

    if (newUserData.members) {
      state.membersList = newUserData.members.map(member => {
        return {
          name: member.display_name,
          avatar: utils.formAvatarURL(member.profile_picture),
          id: member.user_id
        }
      })
    }
  },
  setLoggedIn (state, newLoggedIn) {
    state.loggedIn = newLoggedIn
  },
  setHaveAccess (state, newHaveAccess) {
    state.haveAccess = newHaveAccess
  },
  setLoadingState (state, newLoading) {
    state.loading = newLoading
  },
  setLEDList (state, newLEDData) {
    state.ledList = newLEDData.leds.map(ledData => {
      // Preprocess the list
      ledData.date = moment.unix(ledData.timestamp)

      return ledData
    })
  },
  setExpList (state, newExpListData) {
    state.expList = newExpListData.experiments.map(exp => {
      // Preprocess the list
      let unixTime = 0

      // Deal with the status and time and user
      if (Number(exp.stop) !== 0) {
        exp.status = 'Complete'
        unixTime = exp.stop
        exp.user = exp.chosen_user_stop
      } else if (Number(exp.start) !== 0) {
        exp.status = 'Running'
        unixTime = exp.start
        exp.user = exp.chosen_user_start
      } else if (Number(exp.timestamp) !== 0) {
        exp.status = 'Created'
        unixTime = exp.timestamp
        exp.user = exp.chosen_user
      } else {
        exp.status = 'Error'
        exp.user = exp.chosen_user
      }
      // Add the time
      if (unixTime > 0) {
        exp.time = moment.unix(unixTime)
      }

      // Create a comma separated list of LEDs
      exp.ledString = exp.leds.reduce((ledString, led) => {
        return ledString.length === 0 ? led.name : ledString + ', ' + led.name
      }, '')

      return exp
    })
  },
  setAOList (state, newAOList) {
    state.aoList = newAOList

    state.aoList.forEach(ao => {
      ao.datetime = moment.unix(ao.timestamp)
      ao.value = false
    })
  },
  setResultList (state, newAOList) {
    state.resultList = newAOList

    const leds = []
    state.preselectedResultList = []

    state.resultList.forEach(ao => {
      ao.datetime = moment.unix(ao.timestamp)

      if (!leds.includes(ao.led)) {
        leds.push(ao.led)
        state.preselectedResultList.push(ao)
      }
    })
  },
  setAOListFilters (state, newFilters) {
    state.aoListFilters = newFilters
  },
  setResultListFilters (state, newFilters) {
    state.resultListFilters = newFilters
  },
  setWelcomeData (state, newWelcomeData) {
    state.welcomeNotes = newWelcomeData.notes
    state.welcomeIncomExps = newWelcomeData.experiments_incomplete
    state.welcomeNoAOs = newWelcomeData.experiments_noaos
  }
}

const getters = {
  loading: state => {
    return state.loading
  },
  loggedIn: state => {
    return state.loggedIn
  },
  haveAccess: state => {
    return state.haveAccess
  },
  userData: state => {
    return state.userData
  },
  ledList: state => {
    return state.ledList
  },
  membersList: state => {
    return state.membersList
  },
  expList: state => {
    return state.expList
  },
  aoList: state => {
    return state.aoList
  },
  resultList: state => {
    return state.resultList
  },
  preselectedResultList: state => {
    return state.preselectedResultList
  },
  welcomeNotes: state => {
    return state.welcomeNotes
  },
  welcomeIncomExps: state => {
    return state.welcomeIncomExps
  },
  welcomeNoAOs: state => {
    return state.welcomeNoAOs
  }
}

// Anything that is called a refresh, is just a get request.
// Anything that is called an update, is a post request.
const actions = {
  refreshUserData ({ dispatch }) {
    if (verbose) console.log('User data refresh requested.')

    dispatch('axiosGet', {
      localURL: 'fetch/members'
    })
  },
  refreshWelcomeData ({ dispatch, commit }) {
    if (verbose) console.log('Welcome data refresh requested.')

    dispatch('axiosGet', {
      localURL: 'fetch/welcome',
      responseHandler: responseData => {
        commit('setWelcomeData', responseData)
      }
    })
  },
  refreshLEDList ({ dispatch, commit }) {
    if (verbose) console.log('LED list refresh requested.')

    dispatch('axiosGet', {
      localURL: 'fetch/leds',
      responseHandler: responseData => {
        commit('setLEDList', responseData)
      }
    })
  },
  refreshExpList ({ dispatch, commit }) {
    if (verbose) console.log('Experiment list refresh requested.')

    dispatch('axiosGet', {
      localURL: 'fetch/experiments',
      responseHandler: responseData => {
        commit('setExpList', responseData)
      }
    })
  },
  updateAOList ({ state, dispatch, commit }) {
    if (verbose) console.log('AO list refresh requested.')

    dispatch('axiosPost', {
      localURL: 'fetch/aos',
      postData: state.aoListFilters,
      responseHandler: responseData => {
        commit('setAOList', responseData.aos)
      }
    })
  },
  updateResultList ({ state, dispatch, commit }) {
    if (verbose) console.log('Result list refresh requested.')

    dispatch('axiosPost', {
      localURL: 'fetch/result',
      postData: state.resultListFilters,
      responseHandler: responseData => {
        commit('setResultList', responseData.aos)
      }
    })
  },
  updateNewLEDFields ({ dispatch, state }, { fields, responseHandler }) {
    if (verbose) console.log('New LED field refresh requested.')
    dispatch('axiosPost', {
      localURL: 'form/newled',
      postData: fields,
      responseHandler: responseHandler
    })
  },
  axiosGet ({ dispatch, commit }, { localURL, responseHandler }) {
    commit('setLoadingState', true)

    axios.get(formURL(localURL))
      .then((response) => {
        dispatch('processResponse', { response, responseHandler })
        commit('setLoadingState', false)
      })
      .catch((error) => {
        console.log(error)
        commit('setLoadingState', false)
      })
  },
  axiosPost ({ dispatch, commit }, { localURL, postData, responseHandler }) {
    commit('setLoadingState', true)

    axios.post(formURL(localURL), formPostFromArray(postData))
      .then((response) => {
        dispatch('processResponse', { response, responseHandler })
        commit('setLoadingState', false)
      })
      .catch((error) => {
        console.log(error)
        commit('setLoadingState', false)
      })
  },
  processResponse ({ commit }, { response, responseHandler }) {
    let loggedIn = true
    let haveAccess = true
    // First, check if there is any user data!
    if (!(response.data.user instanceof Object) || response.data.user === null) {
      loggedIn = false
      haveAccess = false
    } else if (response.data.access === 'no') {
      haveAccess = false
    }
    // Check if the user has access
    if (loggedIn && haveAccess) {
      commit('setUserData', response.data)
      // If so, process the data
      // If the returned data has fields in it, then parse them in a way that is suited for the client.
      if (response.data.fields != null) {
        response.data.fields = parseFieldsFromJSON(response.data.fields)
      }
      if (response.data.pages != null) {
        response.data.pages.forEach(page => {
          if (page.fields != null) {
            page.fields = parseFieldsFromJSON(page.fields)
          }
        })
      }
      // Now return the data to the handler
      if (responseHandler) responseHandler(response.data)
    } else {
      // Used in dev
      console.log('A user error was found, see following response data.', response)
      // TODO: This should be moved to the EventBus system
      // If not, then trigger all the registered user error callbacks
      // userErrorSubs.forEach(cb => {
      //   cb.callback(userErrorResponse)
      // })
    }

    commit('setLoggedIn', loggedIn)
    commit('setHaveAccess', haveAccess)
  }
}

// These are a couple of get/post parsers for the server
// This forms the response JSON into fields that I can use
function parseFieldsFromJSON (rawJSONFields) {
  return rawJSONFields.map(fieldData => {
    // Create the rules array, used to store the validation functions
    fieldData.vRules = []
    // First, add a general rule to ensure that the field is filled out, if required
    if (fieldData.required) {
      fieldData.vRules.push(v => !!v || fieldData.title + ' is required')
    }
    // Now add any patterns, if they exist
    // if (fieldData.pattern != null) {
    //   fieldData.vRules.push(v => /fieldData.pattern/.test(v) || fieldData.title + ' must be valid')
    // }
    // If a text field is null, it gets interpreted like that, which is silly. Get rid of that nonsense!
    if (fieldData.value === 'null') {
      fieldData.value = ''
    }
    // Check for errors, and set the error flag accordingly
    fieldData.inError = fieldData.error != null
    // Convert from unix time into a time and a date individually, if datetime
    if (fieldData.type === 'datetime' || fieldData.type === 'datetime_unix') {
      const timestamp = moment.unix(fieldData.value)
      fieldData.time = timestamp.format('HH[:]mm')
      fieldData.rawTime = timestamp.format('HHmmss')
      fieldData.date = timestamp.format('YYYY[-]MM[-]DD')
      fieldData.timeMenu = false
      fieldData.dateMenu = false
    }
    // Add a nosubmit entry, until Simon does it...
    fieldData.nosubmit = (fieldData.type === 'divider') || (fieldData.type === 'code')
    // Finally, return the field to the main array
    return fieldData
  })
}
// This forms the post data from an array
function formPostFromArray (formDataArray) {
  // console.log('Requested array to post', formDataArray)

  // Reduce all the fields key:value pairs to a single string
  return formDataArray.reduce((entries, entry) => {
    // Skip any entries that are not meant to be sent back, or that have no key to send anyway
    if (!entry.nosubmit && entry.key) {
      // Make sure that the value sent is an empty string, not the string "null"!!!
      // if (!entry.value) {
      //   entry.value = ''
      // }
      // Add a preceding & if not the first item to be added to the string
      if (entries.length > 0) entries += '&'
      // Add the string to the accumulator
      entries += (encodeURIComponent(entry.key) + '=' + encodeURIComponent(entry.value || ''))
    }

    return entries
  }, '')
}
// This forms the URL from just the path required, to the full thing
function formURL (scriptName) {
  let fetchSuffix = '.php'
  // const fetchSuffix = '.php?noauth=true'

  // if (process.env.NODE_ENV === 'development' && localStorage.getItem('devAPIKey')) {
  if (localStorage.getItem('devAPIKey')) {
    fetchSuffix += '?apikey=' + localStorage.getItem('devAPIKey')
  }

  return fetchURL + scriptName + fetchSuffix
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
